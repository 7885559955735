<template>
  <lazy-hydrate when-idle>
    <zg-modal
      :visible="optInModal"
      :show-close="false"
    >
      <template slot="title">
        <h4>{{ content?.title }}</h4>
      </template>

      <template slot="text">
        <p>{{ content?.text }}</p>
        <zg-usps
          :usps="content?.usps"
          direction="vertical"
        />
        <div class="modal-buttons">
          <zg-button
            outline
            :size="buttonSize"
            @click="nextStep(false)"
          >
            {{ content?.buttons?.skip }}
          </zg-button>
          <zg-button
            :icons="{ right: 'arrow-forward' }"
            :size="buttonSize"
            @click="nextStep(true)"
          >
            {{ content?.buttons?.approve }}
          </zg-button>
        </div>
      </template>
    </zg-modal>
  </lazy-hydrate>
</template>

<script>
  import LazyHydrate from 'vue-lazy-hydration'

  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'AutoOptInModal',
    components: {
      LazyHydrate,
      ZgModal: () => import(/* webpackChunkName: 'zc/zg-modal' */
        '@zc/components/ZgModal/ZgModal'),
      ZgUsps: () => import(/* webpackChunkName: 'zc/zg-modal' */
        '@zc/components/ZgUsps/ZgUsps'),
      ZgButton: () => import(/* webpackChunkName: 'zc/zg-modal' */
        '@zc/components/ZgButton/ZgButton')
    },
    computed: {
      ...mapGetters('cl/formSteps', ['optInModal']),
      ...mapGetters('content', ['getContent']),
      ...mapGetters('misc', ['getIsMobile']),

      content () {
        return this.getContent?.elements?.autoOptIn ?? {}
      },
      buttonSize () {
        return this.getIsMobile ? 'large' : 'xlarge'
      }
    },
    mounted () {
      // NOTE: To prevent hydration failure.
      this.setField({
        group: 'global',
        field: 'autoOptIn',
        touched: false,
        value: undefined
      })
      this.setOptInModal(false)
    },
    methods: {
      ...mapActions('cl/formSteps', ['goToNextStep', 'setOptInModal']),
      ...mapActions('form', ['setField']),
      ...mapActions('cl/analytics', ['buttonTracking']),

      async nextStep (optIn) {
        this.setField({
          group: 'global',
          field: 'autoOptIn',
          touched: true,
          value: optIn
        })

        this.setOptInModal(false)
        this.goToNextStep()
        await this.buttonTracking({ label: optIn ? 'auto-sbu.yes' : 'auto-sbu.no' })
      }
    }
  }
</script>

<style lang="scss" scoped>
h4 {
  margin-bottom: 0;
}

p {
  @include type-body-l;

  margin-bottom: rhythm(medium)
}

.modal-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rhythm(medium);
  gap: rem(16px);
  flex-wrap: wrap;

  @include mq(medium) {
    justify-content: flex-end;
  }
}
</style>
