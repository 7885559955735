var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lazy-hydrate",
    { attrs: { "when-idle": "" } },
    [
      _c(
        "zg-modal",
        { attrs: { visible: _vm.optInModal, "show-close": false } },
        [
          _c("template", { slot: "title" }, [
            _c("h4", [_vm._v(_vm._s(_vm.content?.title))]),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "text" },
            [
              _c("p", [_vm._v(_vm._s(_vm.content?.text))]),
              _vm._v(" "),
              _c("zg-usps", {
                attrs: { usps: _vm.content?.usps, direction: "vertical" },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-buttons" },
                [
                  _c(
                    "zg-button",
                    {
                      attrs: { outline: "", size: _vm.buttonSize },
                      on: {
                        click: function ($event) {
                          return _vm.nextStep(false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.content?.buttons?.skip) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "zg-button",
                    {
                      attrs: {
                        icons: { right: "arrow-forward" },
                        size: _vm.buttonSize,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.nextStep(true)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.content?.buttons?.approve) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }